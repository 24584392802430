import * as React from 'react';
import { RefObject, useState } from 'react';
import styled from 'styled-components';
import { useMountEffect } from '../hooks/useMountEffect';

const ProgressBar = styled.div`
	position: fixed;
	height: 6px;
	margin-top: -1px;
	background-color: var(--active-color);
	z-index: 90;
`;

interface ReadingProgress {
	target: RefObject<HTMLElement | null>;
}

export const ReadingProgress: React.FC<ReadingProgress> = ({ target }) => {
	const [readingProgress, setReadingProgress] = useState(0);
	const scrollListener = () => {
		if (!target.current) {
			return;
		}

		const element = target.current;
		const totalHeight = element.clientHeight - element.offsetTop - window.innerHeight;
		const windowScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

		if (windowScrollTop === 0) {
			return setReadingProgress(0);
		}

		if (windowScrollTop > totalHeight) {
			return setReadingProgress(100);
		}

		setReadingProgress((windowScrollTop / totalHeight) * 100);
	};

	useMountEffect(() => {
		window.addEventListener('scroll', scrollListener);
		return () => window.removeEventListener('scroll', scrollListener);
	});

	return <ProgressBar style={{ width: `${readingProgress}%` }} />;
};

import * as React from 'react';
import styled from 'styled-components';
import { LocalizedLink } from './LocalizedLink';
import { graphql, useStaticQuery } from 'gatsby';
import ArrowRight from '../images/nool_paremale.svg';
import ArrowLeft from '../images/nool_vasakule.svg';
import { screenSize } from '../styles/ScreenSize';
import { AllMenuYamlQuery } from '../generated/graphql-types';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useLocaleContext } from '../contexts/LocaleContext';

//<editor-fold desc="Styled Components">
export const NavigationBarWrapper = styled.div`
	color: #0072ce;
	background-color: var(--grey);
	grid-area: navigation;
	width: 100%;
	height: 48px;
	padding: 8px var(--padding);
	display: flex;
	flex-direction: row;
	align-items: center;

	a {
		flex: 1;
		text-decoration: none;
		color: #0072ce;
		display: flex;
		flex-flow: row;
		align-items: center;
	}

	a:first-child {
		justify-content: flex-start;

		svg {
			margin-right: 0.56em;
		}
	}

	a:last-child {
		justify-content: flex-end;

		svg {
			margin-left: 0.56em;
		}
	}

	svg {
		height: 0.9em;
	}

	a:hover {
		color: var(--hover-color);
	}

	a:hover svg * {
		fill: var(--hover-color);
	}
`;

const OnlySm = styled.span`
	display: none;

	${screenSize.sm} {
		display: initial;
	}
`;

const NotSm = styled.span`
	display: initial;

	${screenSize.sm} {
		display: none;
	}
`;

export const CurrentPage = styled.div`
	text-align: center;
`;

//</editor-fold>

interface NavigationPage {
	href: string;
	title: string;
}

interface PageNavigationBar {
	currentPage: NavigationPage;
}

export const PageNavigationBar: React.FC<PageNavigationBar> = ({ currentPage }) => {
	const { locale } = useLocaleContext();

	const {
		allMenuYaml: { nodes: menu },
	} = useStaticQuery<AllMenuYamlQuery>(graphql`
		query AllMenuYaml {
			allMenuYaml {
				nodes {
					contents {
						fields {
							locale
						}
						frontmatter {
							slug
							title
						}
					}
				}
			}
		}
	`);

	const pages = menu.map(({ contents }) => {
		const content = contents?.find((content) => content?.fields?.locale === locale);

		return {
			title: content?.frontmatter?.title,
			href: `/${content?.frontmatter?.slug}`,
		};
	});

	const currentIndex = pages.findIndex((page) => page.href === currentPage.href);
	const previousPageIndex = (currentIndex + pages.length - 1) % pages.length;

	const previousPage = pages[previousPageIndex];
	const nextPageIndex = (currentIndex + 1) % pages.length;
	const nextPage = pages[nextPageIndex];

	const { t } = useTranslation();
	return (
		<NavigationBarWrapper>
			{previousPageIndex < currentIndex && (
				<LocalizedLink to={previousPage.href}>
					<ArrowLeft aria-hidden="true" />
					<NotSm>{previousPage.title}</NotSm>
					<OnlySm>{t('Eelmine')}</OnlySm>
				</LocalizedLink>
			)}
			<CurrentPage>{/* {currentIndex + 1}/{pages.length} */}</CurrentPage>
			{nextPageIndex > currentIndex && (
				<LocalizedLink to={nextPage.href}>
					<NotSm>{nextPage.title}</NotSm>
					<OnlySm>{t('Järgmine')}</OnlySm>
					<ArrowRight aria-hidden="true" />
				</LocalizedLink>
			)}
		</NavigationBarWrapper>
	);
};

import * as React from 'react';
import { useEffect, useRef } from 'react';
import { graphql, PageProps } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { userShortcodes } from '../../lib/userShortcodes';
import styled, { ThemeProvider } from 'styled-components';
import { HeroContent, HeroWrapper } from '../content/Hero';
import { ContentWrapper } from '../content/Content';
import { MenuDomains } from '../menu/MenuDomains';
import { PageNavigationBar } from '../PageNavigationBar';
import { useLayoutContext } from '../../contexts/LayoutContext';
import { useMountEffect } from '../../hooks/useMountEffect';
import { ReadingProgress } from '../ReadingProgress';
import { screenSize } from '../../styles/ScreenSize';
import { ContentPageQueryQuery } from '../../generated/graphql-types';
import SEO from '../seo';
import { useLocaleContext } from '../../contexts/LocaleContext';

//<editor-fold desc="Styled Components">
const Main = styled.main`
	display: flex;
	flex-flow: column;
	align-items: center;

	h1,
	h2 {
		color: inherit;
		border: none;
		font-size: 45px;
		line-height: 1;

		${screenSize.sm} {
			font-size: 32px;
			line-height: 1.1;
		}
	}

	ol {
		list-style: none;
		counter-reset: ol;
		font-size: inherit;
		line-height: inherit;
		margin-top: 0;

		padding-inline-start: 40px;

		${screenSize.sm} {
			padding-inline-start: 22px;
		}
	}

	ol > li {
		counter-increment: count;
		position: relative;
		margin-bottom: 0;
	}

	ol > li:not(:first-of-type) {
		margin-top: 2rem;
	}

	ol > li::before {
		content: counter(count) '.';
		color: inherit;
		position: absolute;

		left: -40px;

		${screenSize.sm} {
			left: -22px;
		}
	}

	${HeroContent}, ${ContentWrapper} {
		max-width: var(--content-width);
		width: 100%;
	}

	${HeroWrapper} {
		display: flex;
		justify-content: center;
		width: 100%;
		min-height: 0;
		background-color: ${(p) => p.theme.bgColor};
		color: ${(p) => p.theme.fgColor};
		padding-bottom: 32px;
	}

	${HeroContent} {
		padding: var(--padding);
	}

	${ContentWrapper} {
		padding-top: var(--content-top-margin);
		padding-bottom: var(--content-bottom-margin);

		h2 {
			padding-bottom: 24px;
		}

		.gatsby-resp-image-wrapper {
			margin: 32px 0;
		}
	}
`;
//</editor-fold>

export default function ContentTemplate({ data, pageContext }: PageProps<ContentPageQueryQuery, {translations: any}>) {
	const { setBreadcrumbs } = useLayoutContext();
	const {setPageTranslations} = useLocaleContext();
	const targetMain = useRef<HTMLDivElement>(null);
	const frontmatter = data.mdx?.frontmatter;

	useEffect(() => {
		setPageTranslations(pageContext.translations)
	}, []);

	const currentPage = {
		title: frontmatter?.title || '',
		href: `/${frontmatter?.slug}`,
	};

	const currentTheme = {
		fgColor: frontmatter?.group === 'valdkond' ? '#fff' : '#000',
		bgColor: frontmatter?.background,
	};

	useMountEffect(() => {
		setBreadcrumbs([currentPage]);
	});

	return (
		<ThemeProvider theme={currentTheme}>
			<SEO title={frontmatter?.title}/>
			<ReadingProgress target={targetMain} />
			<Main ref={targetMain}>
				<MDXProvider components={userShortcodes}>
					<MDXRenderer>{data.mdx?.body || ''}</MDXRenderer>
				</MDXProvider>
				{!frontmatter?.group ||
					(!['map', 'lisa', 'valdkond'].includes(frontmatter?.group) && (
						<PageNavigationBar currentPage={currentPage} />
					))}
				{frontmatter?.group === 'valdkond' && <MenuDomains />}
			</Main>
		</ThemeProvider>
	);
}

export const pageQuery = graphql`
	query ContentPageQuery($basename: String!, $language: String!) {
		mdx(fields: {basename: {eq: $basename}, locale: {eq: $language}}) {
			id
			body
			frontmatter {
				title
				slug
				background
				group
			}
		}
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
